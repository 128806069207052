import Chrome from "../../lib/chrome/Chrome";
import * as React from "react";
import { useMemo } from "react";
import { useReportState } from "./StateContextProvider";
import { DateTime } from "luxon";
import { useReportEffects } from "./EffectsContextProvider";
import ComboBox from "../../lib/form/select/ComboBox";
import WeekSelect from "../../lib/form/week-selector/WeekSelect";
import YearSelect from "../../lib/form/year-selector/YearSelect";
import ThresholdsControl from "./ThresholdsControl";
import Checkbox from "../../lib/form/checkbox/Checkbox";

export function Header() {
    const {
        accessibleUnitRefs,
        firstYear,
        unitIds,
        week,
        year,
        thresholds,
        showApproved,
        showMatching
    } = useReportState();

    const currentYear = DateTime.local().weekYear;

    const { setUnitIds, setWeek, setThreshold, setShowApproved, setShowMatching } = useReportEffects();

    const handleUnitIdsChanged = (newUnitIds: string[]) => {
        setUnitIds(newUnitIds);
    };

    const handleWeekChanged = (newWeek: number) => {
        setWeek(newWeek, year);
    };

    const handleYearChanged = (newYear: number) => {
        setWeek(week, newYear);
    };

    const handleShowApprovedChanged = (newShowApproved: boolean) => {
        setShowApproved(newShowApproved);
    };

    const handleShowMatchingChanged = (newShowMatching: boolean) => {
        setShowMatching(newShowMatching);
    };

    const unitOptions = useMemo(() => ({
        Alle: accessibleUnitRefs.reduce((memo, unit) => {
            memo.push({ label: unit.name, value: unit.id });
            return memo;
        }, [])
    }), [accessibleUnitRefs]);

    return (<>
            <Chrome.DetailPage.Header>
                <Chrome.DetailPage.HeaderMain>
                    <Chrome.DetailPage.PageSupertitle>Planung</Chrome.DetailPage.PageSupertitle>
                    <Chrome.DetailPage.PageTitle>Stempelzeitkontrolle</Chrome.DetailPage.PageTitle>
                </Chrome.DetailPage.HeaderMain>
            </Chrome.DetailPage.Header>

            <Chrome.DetailPage.ActionBar split>
                <div className="d-flex gap-2 align-items-start justify-content-start w-100">
                    <div className="d-flex flex-column gap-2 justify-content-start h-100" style={{ flex: "0 0 500px" }}>
                        <ComboBox
                            allLabel="Alle Filialen"
                            multiselect={true}
                            value={unitIds}
                            groupedOptions={unitOptions}
                            onChange={handleUnitIdsChanged}
                        />
                        <div className="d-flex gap-2 align-items-end">
                            <YearSelect
                                aria-label="Jahr"
                                minYear={firstYear}
                                maxYear={currentYear}
                                value={year}
                                onChange={handleYearChanged}
                            />

                            <WeekSelect
                                aria-label="Kalenderwoche"
                                year={year}
                                value={week}
                                onChange={handleWeekChanged}
                            />
                        </div>
                        <div className="d-flex gap-3 align-items-start justify-content-start">
                            <Checkbox
                                type="checkbox"
                                aria-label="Genehmigte anzeigen"
                                caption="Genehmigte anzeigen"
                                checked={showApproved}
                                onChange={checked => handleShowApprovedChanged(checked)}
                            />
                            <Checkbox
                                type="checkbox"
                                aria-label="Alle anzeigen"
                                caption="Alle anzeigen"
                                checked={showMatching}
                                onChange={checked => handleShowMatchingChanged(checked)}
                            />
                        </div>
                    </div>
                    <div className="d-flex gap-2 align-items-end">
                        <ThresholdsControl
                            thresholds={thresholds}
                            onChange={setThreshold}
                        />
                    </div>
                </div>
                <div>
                    {/* Place export button here... */}
                </div>
            </Chrome.DetailPage.ActionBar>
        </>
    );
}
