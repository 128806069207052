import * as React from "react";
import { useMemo } from "react";
import { joinClasses } from "../../dom-utils/class-names";
import { DateTime } from "luxon";
import Icon from "../../icon/Icon";

interface YearSelectProps {
    value: number;
    minYear?: number;
    maxYear?: number;
    onChange: (year: number) => void;
}

export default function YearSelect(props: YearSelectProps) {
    const { value: selectedYear, onChange } = props;

    const firstYear = props.minYear || selectedYear - 2;
    const lastYear = props.maxYear || selectedYear + 2;

    const yearsRange = useMemo(() => {
        const years = [];
        for (let i = firstYear; i <= lastYear; i++) {
            years.push(i);
        }
        return years;
    }, [selectedYear]);

    const yearOptions = useMemo(() => {
        return yearsRange.map((year) => {
            return {
                label: year.toString(),
                value: year
            };
        });
    }, [selectedYear, yearsRange]);

    return (
        <div className="d-flex justify-content-center gap-1" style={{ width: "110px" }}>
            <select
                className="form-select"
                aria-label="Jahr"
                style={{ minWidth: "80px", maxWidth: "110px" }}
                value={selectedYear}
                onChange={(e) => onChange(Number(e.target.value))}
            >
                {yearOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
}
