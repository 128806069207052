import * as React from "react";
import { EmployeeDay } from "../state/state";
import Icon from "../../../lib/icon/Icon";
import Button from "../../../lib/button/Button";

type GridApprovalStatusProps = {
    approved: EmployeeDay["approved"];
    approvedBy: EmployeeDay["approvedBy"];
    approvedOn: EmployeeDay["approvedOn"];
    onApprove: () => unknown;
    onUnapprove: () => unknown;
}

export default function GridApprovalStatus(props: GridApprovalStatusProps) {
    const { approved, approvedBy, approvedOn, onApprove, onUnapprove } = props;
    return (
        <div className="EttAuditGrid__EmployeeDay__Col EttAuditGrid__EmployeeDay__ApprovalStatus">
            {approved ? (
                <div className="text-end">
                    <div
                        className="EttAuditGrid__EmployeeDay__ApprovalStatus__Remarks">
                        Erledigt von
                        {" "}
                        {approvedBy}
                        {" "}
                        am
                        {" "}
                        {approvedOn}.
                    </div>
                    <a
                        className="EttAuditGrid__EmployeeDay__ApprovalStatus__UnapproveLink"
                        onClick={onUnapprove}
                    >
                        Rückgängig
                        {" "}
                        <Icon type="undo" />
                    </a>
                </div>
            ) : (
                <Button
                    icon="apply"
                    theme="light"
                    onClick={onApprove}
                    // onMouseUp={onApprove}
                >
                    Als erledigt markieren
                </Button>
            )}
        </div>
    );
}
