import { Action } from "./actions";
import { Day, EmployeeDay, State } from "./state";
import { DateTime } from "luxon";

export function reducer(draft: State, action: Action) {
    console.debug("🟦 ACTION", action);
    switch (action.type) {
        case "startLoadingAccessibleUnits":
            draft.loadingAccessibleUnits = true;
            draft.loadAccessibleUnitsError = null;
            break;

        case "succeedLoadingAccessibleUnits":
            draft.accessibleUnitRefs = action.payload;
            draft.loadingAccessibleUnits = false;
            draft.loadAccessibleUnitsError = null;
            break;

        case "failLoadingAccessibleUnits":
            draft.loadAccessibleUnitsError = action.payload;
            draft.loadingAccessibleUnits = false;
            break;

        case "setUnitIds":
            draft.unitIds = action.payload;
            break;

        case "setWeek":
            draft.year = action.payload.year;
            draft.week = action.payload.week;
            break;

        case "setThreshold":
            draft.thresholds = action.payload;
            break;

        case "setShowApproved":
            draft.showApproved = action.payload;
            draft.filteredDays = filterDays(draft.days, draft.showApproved);
            break;

        case "setShowMatching":
            draft.showMatching = action.payload;
            draft.filteredDays = filterDays(draft.days, draft.showApproved);
            break;

        case "startLoading":
            draft.loading = true;
            draft.loadingError = null;
            break;

        case "succeedLoading":
            draft.loading = false;
            draft.loadingError = null;
            draft.days = action.payload;
            draft.filteredDays = filterDays(draft.days, draft.showApproved);
            break;

        case "failLoading":
            draft.loading = false;
            draft.loadingError = action.payload;
            break;

        case "restoreExternalFilterParams":
            draft.filterParamsRestored = true;
            draft.year = action.payload.year;
            draft.week = action.payload.week;
            draft.unitIds = action.payload.unitIds;
            draft.thresholds = action.payload.threshold;
            draft.showApproved = action.payload.showApproved;
            draft.showMatching = action.payload.showMatching;
            break;

        case "approveEmployeeDay":
            approveEmployeeDay(draft, action.payload.date, action.payload.employeeId);
            draft.filteredDays = filterDays(draft.days, draft.showApproved);
            break;

        case "unapproveEmployeeDay":
            unapproveEmployeeDay(draft, action.payload.date, action.payload.employeeId);
            draft.filteredDays = filterDays(draft.days, draft.showApproved);
            break;
    }
}

function filterDays(days: Day[], showApproved: boolean): Day[] {
    const res = days.map(day => ({
        ...day,
        employeeDays: day.employeeDays.filter(employeeDay => showApproved || !employeeDay.approved)
    }));

    return res.filter(day => day.employeeDays.length > 0);
}

function approveEmployeeDay(state: State, date: Day["date"], employeeId: string) {
    modifyEmployeeDay(state, date, employeeId, (employeeDay) => {
            employeeDay.approved = true;
            employeeDay.approvedBy = "You";
            employeeDay.approvedOn = DateTime.local().toFormat("D");
        }
    );
}

function unapproveEmployeeDay(state: State, date: Day["date"], employeeId: string) {
    modifyEmployeeDay(state, date, employeeId, (employeeDay) => {
            employeeDay.approved = false;
            employeeDay.approvedBy = "";
            employeeDay.approvedOn = "";
        }
    );
}

function modifyEmployeeDay(state: State, date: Day["date"], employeeId: string, modify: (employeeDay: EmployeeDay) => void) {
    const day = state.days.find(day => day.date === date);
    if (!day) {
        console.warn("Day not found");
        return null;
    }

    const employeeDay = day.employeeDays.find(employeeDay => employeeDay.employeeRef.id === employeeId);
    if (!employeeDay) {
        console.warn("Employee day not found");
        return null;
    }

    modify(employeeDay);
}
