import { Dispatch } from "react";
import { Action } from "./actions";
import { SetURLSearchParams } from "react-router-dom";
import { setFilterToStorage } from "../filters";
import { AccessibleUnitRef, Day, ThresholdsValue } from "./state";
import { ExternalFilterParamsType } from "../ExternalFilterParams";
import { deleteEmployeeDayApproval, postEmployeeDayApproval } from "../api";

export type Effects = {
    startLoadingAccessibleUnits: () => unknown;
    succeedLoadingAccessibleUnits: (accessibleUnits: AccessibleUnitRef[]) => unknown;
    failLoadingAccessibleUnits: (loadingError: string) => unknown;
    setUnitIds: (unitIds: string[]) => unknown;
    setWeek: (year: number, week: number) => unknown;
    setThreshold: (filter: ThresholdsValue) => unknown;
    setShowApproved: (showApproved: boolean) => unknown;
    setShowMatching: (showMatching: boolean) => unknown;
    startLoading: () => unknown;
    succeedLoading: (days: Day[]) => unknown;
    failLoading: (loadingError: string) => unknown;
    restoreExternalFilterParams: (filterParams: ExternalFilterParamsType) => unknown;
    approveEmployeeDay: (date: Day["date"], employeeId: string) => unknown;
    unapproveEmployeeDay: (date: Day["date"], employeeId: string) => unknown;
}

export function createEffects(dispatch: Dispatch<Action>, setSearchParams: SetURLSearchParams): Effects {
    return {
        startLoadingAccessibleUnits: startLoadingAccessibleUnitsFactory(dispatch),
        succeedLoadingAccessibleUnits: succeedLoadingAccessibleUnitsFactory(dispatch),
        failLoadingAccessibleUnits: failLoadingAccessibleUnitsFactory(dispatch),
        setUnitIds: setUnitIdsFactory(dispatch, setSearchParams),
        setWeek: setWeekFactory(dispatch, setSearchParams),
        setThreshold: setThresholdFactory(dispatch, setSearchParams),
        setShowApproved: setShowApprovedFactory(dispatch, setSearchParams),
        setShowMatching: setShowMatchingFactory(dispatch, setSearchParams),
        startLoading: startLoadingFactory(dispatch),
        succeedLoading: succeedLoadingFactory(dispatch),
        failLoading: failLoadingFactory(dispatch),
        restoreExternalFilterParams: restoreExternalFilterParamsFactory(dispatch, setSearchParams),
        approveEmployeeDay: approveEmployeeDayFactory(dispatch),
        unapproveEmployeeDay: unapproveEmployeeDayFactory(dispatch)
    };
}

function startLoadingAccessibleUnitsFactory(dispatch: Dispatch<Action>) {
    return () => {
        dispatch({
            type: "startLoadingAccessibleUnits"
        });
    };
}

function succeedLoadingAccessibleUnitsFactory(dispatch: Dispatch<Action>) {
    return (accessibleUnits: AccessibleUnitRef[]) => {
        dispatch({
            type: "succeedLoadingAccessibleUnits",
            payload: accessibleUnits
        });
    };
}

function failLoadingAccessibleUnitsFactory(dispatch: Dispatch<Action>) {
    return (loadingError: string) => {
        dispatch({
            type: "failLoadingAccessibleUnits",
            payload: loadingError
        });
    };
}

function setUnitIdsFactory(dispatch: Dispatch<Action>, setSearchParams: SetURLSearchParams) {
    return (unitIds: string[]) => {
        setSearchParams(searchParams => {
            searchParams.set("unitIds", unitIds.join(","));
            return searchParams;
        });

        setFilterToStorage("unitIds", unitIds.join(","));

        dispatch({
            type: "setUnitIds",
            payload: unitIds
        });
    };
}

function setWeekFactory(dispatch: Dispatch<Action>, setSearchParams: SetURLSearchParams) {
    return (week: number, year: number) => {
        setSearchParams((searchParams => {
            searchParams.set("year", year.toString());
            searchParams.set("week", week.toString());
            return searchParams;
        }));

        setFilterToStorage("year", year.toString());
        setFilterToStorage("week", week.toString());

        dispatch({
            type: "setWeek",
            payload: { week, year }
        });
    };
}

function setThresholdFactory(dispatch: Dispatch<Action>, setSearchParams: SetURLSearchParams) {
    return (filter: ThresholdsValue) => {
        setSearchParams(searchParams => {
            searchParams.set("lowerStart", filter.lowerStart.toString());
            searchParams.set("upperStart", filter.upperStart.toString());
            searchParams.set("lowerEnd", filter.lowerEnd.toString());
            searchParams.set("upperEnd", filter.upperEnd.toString());
            searchParams.set("lowerBreak", filter.lowerBreak.toString());
            searchParams.set("upperBreak", filter.upperBreak.toString());
            return searchParams;
        });

        setFilterToStorage("lowerStart", filter.lowerStart.toString());
        setFilterToStorage("upperStart", filter.upperStart.toString());
        setFilterToStorage("lowerEnd", filter.lowerEnd.toString());
        setFilterToStorage("upperEnd", filter.upperEnd.toString());
        setFilterToStorage("lowerBreak", filter.lowerBreak.toString());
        setFilterToStorage("upperBreak", filter.upperBreak.toString());

        dispatch({
            type: "setThreshold",
            payload: filter
        });
    };
}

function setShowApprovedFactory(dispatch: Dispatch<Action>, setSearchParams: SetURLSearchParams) {
    return (showApproved: boolean) => {
        setSearchParams(searchParams => {
            searchParams.set("showApproved", showApproved.toString());
            return searchParams;
        });

        setFilterToStorage("showApproved", showApproved.toString());

        dispatch({
            type: "setShowApproved",
            payload: showApproved
        });
    };
}

function setShowMatchingFactory(dispatch: Dispatch<Action>, setSearchParams: SetURLSearchParams) {
    return (showMatching: boolean) => {
        setSearchParams(searchParams => {
            searchParams.set("showMatching", showMatching.toString());
            return searchParams;
        });

        setFilterToStorage("showMatching", showMatching.toString());

        dispatch({
            type: "setShowMatching",
            payload: showMatching
        });
    };
}

function startLoadingFactory(dispatch: Dispatch<Action>) {
    return () => {
        dispatch({
            type: "startLoading"
        });
    };
}

function succeedLoadingFactory(dispatch: Dispatch<Action>) {
    return (days: Day[]) => {
        dispatch({
            type: "succeedLoading",
            payload: days
        });
    };
}

function failLoadingFactory(dispatch: Dispatch<Action>) {
    return (loadingError: string) => {
        dispatch({
            type: "failLoading",
            payload: loadingError
        });
    };
}

function restoreExternalFilterParamsFactory(dispatch: Dispatch<Action>, setSearchParams: SetURLSearchParams) {
    return (filterParams: ExternalFilterParamsType) => {
        dispatch({
            type: "restoreExternalFilterParams",
            payload: filterParams
        });

        setSearchParams(searchParams => {
            searchParams.set("unitIds", filterParams.unitIds.join(","));
            searchParams.set("year", filterParams.year.toString());
            searchParams.set("week", filterParams.week.toString());
            searchParams.set("lowerStart", filterParams.threshold.lowerStart.toString());
            searchParams.set("upperStart", filterParams.threshold.upperStart.toString());
            searchParams.set("lowerEnd", filterParams.threshold.lowerEnd.toString());
            searchParams.set("upperEnd", filterParams.threshold.upperEnd.toString());
            searchParams.set("lowerBreak", filterParams.threshold.lowerBreak.toString());
            searchParams.set("upperBreak", filterParams.threshold.upperBreak.toString());
            searchParams.set("showApproved", filterParams.showApproved.toString());
            return searchParams;
        });

        setFilterToStorage("year", filterParams.year.toString());
        setFilterToStorage("week", filterParams.week.toString());
        setFilterToStorage("lowerStart", filterParams.threshold.lowerStart.toString());
        setFilterToStorage("upperStart", filterParams.threshold.upperStart.toString());
        setFilterToStorage("lowerEnd", filterParams.threshold.lowerEnd.toString());
        setFilterToStorage("upperEnd", filterParams.threshold.upperEnd.toString());
        setFilterToStorage("lowerBreak", filterParams.threshold.lowerBreak.toString());
        setFilterToStorage("upperBreak", filterParams.threshold.upperBreak.toString());
        setFilterToStorage("showApproved", filterParams.showApproved.toString());
    };
}

function approveEmployeeDayFactory(dispatch: Dispatch<Action>) {
    return (date: Day["date"], employeeId: string) => {
        dispatch({
            type: "approveEmployeeDay",
            payload: { date, employeeId }
        });
        postEmployeeDayApproval(date, employeeId);
    };
}

function unapproveEmployeeDayFactory(dispatch: Dispatch<Action>) {
    return (date: Day["date"], employeeId: string) => {
        dispatch({
            type: "unapproveEmployeeDay",
            payload: { date, employeeId }
        });
        deleteEmployeeDayApproval(date, employeeId);
    };
}
