import * as React from "react";
import { ReactNode, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useReportEffects } from "./EffectsContextProvider";
import { getFilterFromStorage } from "./filters";
import { useReportState } from "./StateContextProvider";

export type ExternalFilterParamsType = {
    unitIds: string[],
    week: number,
    year: number,
    threshold: {
        lowerStart: number,
        upperStart: number,
        lowerEnd: number,
        upperEnd: number,
        lowerBreak: number,
        upperBreak: number,
    },
    showApproved: boolean,
    showMatching: boolean,
}

export function ExternalFilterParams(props: { children: ReactNode }) {
    useExternalFilterParams();
    return <>{props.children}</>;
}

function useExternalFilterParams() {
    const { restoreExternalFilterParams } = useReportEffects();

    const { filterParamsRestored } = useReportState();
    const valueFromParams = useFilterParamsFromUrl();
    const valueFromStorage = useDateRangeFromStorage();

    useEffect(() => {
        if (filterParamsRestored) {
            return;
        }

        if (valueFromParams) {
            restoreExternalFilterParams(valueFromParams);
        } else if (valueFromStorage) {
            restoreExternalFilterParams(valueFromStorage);
        }
    }, [filterParamsRestored, JSON.stringify(valueFromParams), JSON.stringify(valueFromStorage)]);
}

function useFilterParamsFromUrl(): ExternalFilterParamsType | undefined {
    const [searchParams, _setSearchParams] = useSearchParams();

    const unitIds = searchParams.getAll("unitIds");
    const week = searchParams.get("week");
    const year = searchParams.get("year");
    const lowerStart = searchParams.get("lowerStart");
    const upperStart = searchParams.get("upperStart");
    const lowerEnd = searchParams.get("lowerEnd");
    const upperEnd = searchParams.get("upperEnd");
    const lowerBreak = searchParams.get("lowerBreak");
    const upperBreak = searchParams.get("upperBreak");
    const showApproved = searchParams.get("showApproved") === "true";
    const showMatching = searchParams.get("showMatching") === "true";

    return useMemo(() => {
        if (unitIds && week && year && lowerStart && upperStart && lowerEnd && upperEnd && lowerBreak && upperBreak) {
            return {
                unitIds,
                week: parseInt(week),
                year: parseInt(year),
                threshold: {
                    lowerStart: parseInt(lowerStart),
                    upperStart: parseInt(upperStart),
                    lowerEnd: parseInt(lowerEnd),
                    upperEnd: parseInt(upperEnd),
                    lowerBreak: parseInt(lowerBreak),
                    upperBreak: parseInt(upperBreak)
                },
                showMatching,
                showApproved
            };
        }
    }, [unitIds, week, year, lowerStart, upperStart, lowerEnd, upperEnd, lowerBreak, upperBreak, showApproved]);
}

function useDateRangeFromStorage(): ExternalFilterParamsType | undefined {
    const rawUnitIds = getFilterFromStorage("unitIds");
    const unitIds = rawUnitIds ? rawUnitIds.split(",") : undefined;
    const week = getFilterFromStorage("week");
    const year = getFilterFromStorage("year");
    const lowerStart = getFilterFromStorage("lowerStart");
    const upperStart = getFilterFromStorage("upperStart");
    const lowerEnd = getFilterFromStorage("lowerEnd");
    const upperEnd = getFilterFromStorage("upperEnd");
    const lowerBreak = getFilterFromStorage("lowerBreak");
    const upperBreak = getFilterFromStorage("upperBreak");
    const showApproved = getFilterFromStorage("showApproved") === "true";
    const showMatching = getFilterFromStorage("showMatching") === "true";

    return useMemo(() => {
        if (unitIds && unitIds.length > 0 && week && year && lowerStart && upperStart && lowerEnd && upperEnd && lowerBreak && upperBreak) {
            return {
                unitIds,
                week: parseInt(week),
                year: parseInt(year),
                threshold: {
                    lowerStart: parseInt(lowerStart),
                    upperStart: parseInt(upperStart),
                    lowerEnd: parseInt(lowerEnd),
                    upperEnd: parseInt(upperEnd),
                    lowerBreak: parseInt(lowerBreak),
                    upperBreak: parseInt(upperBreak)
                },
                showApproved,
                showMatching
            };
        }
    }, [unitIds, week, year, lowerStart, upperStart, lowerEnd, upperEnd, lowerBreak, upperBreak, showApproved]);
}
