import * as React from "react";
import { useCallback } from "react";
import { useReportState } from "../StateContextProvider";
import { useReportEffects } from "../EffectsContextProvider";
import GridDay from "./GridDay";

export default function ReportGrid() {
    const { filteredDays } = useReportState();
    const { approveEmployeeDay, unapproveEmployeeDay } = useReportEffects();

    const handleApprove = useCallback((...args: Parameters<typeof approveEmployeeDay>) => {
        approveEmployeeDay(...args);
    }, []);

    const handleUnapprove = useCallback((...args: Parameters<typeof unapproveEmployeeDay>) => {
        unapproveEmployeeDay(...args);
    }, []);

    return (
        <div className="EttAuditGrid">
            {filteredDays.map(day => (
                <GridDay
                    key={day.date.toString()}
                    day={day}
                    onApprove={handleApprove}
                    onUnapprove={handleUnapprove}
                />
            ))}
        </div>
    );
}
