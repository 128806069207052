import * as React from "react";
import { EmployeeDay } from "../state/state";
import GridEttTimes from "./GridEttTimes";
import GridEmployee from "./GridEmployee";
import GridDeployedTimes from "./GridDeployedTimes";
import GridApprovalStatus from "./GridApprovalStatus";

type DayProps = {
    employeeDay: EmployeeDay;
    onApprove: () => unknown;
    onUnapprove: () => unknown;
}

export default function GridEmployeeDay(props: DayProps) {
    const { employeeDay, onApprove, onUnapprove } = props;
    return (
        <div
            className={`EttAuditGrid__Day__Row EttAuditGrid__EmployeeDay ${employeeDay.approved ? "EttAuditGrid__EmployeeDay--approved" : ""}`}
            key={employeeDay.employeeRef.id}
        >
            <GridEmployee
                employeeRef={employeeDay.employeeRef}
            />

            <GridEttTimes
                employeeDay={employeeDay}
            />

            <GridDeployedTimes
                deployedTimes={employeeDay.deployedTimes}
            />

            <GridApprovalStatus
                approved={employeeDay.approved}
                approvedBy={employeeDay.approvedBy}
                approvedOn={employeeDay.approvedOn}
                onApprove={onApprove}
                onUnapprove={onUnapprove}
            />

        </div>
    );
}
