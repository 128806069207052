import * as React from "react";
import { useMemo } from "react";
import ErrorBoundary from "../../lib/errors/ErrorsBoundary";
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from "react-router-dom";
import { ReportBrowser } from "./ReportBrowser";
import { DataFromApi } from "./DataFromApi";
import StateContextProvider from "./StateContextProvider";
import EffectsContextProvider from "./EffectsContextProvider";
import { ExternalFilterParams } from "./ExternalFilterParams";
import AccessibleUnitsGuard from "./AccessibleUnitsGuard";

export default function EttAudit() {
    const router = useMemo(() => createBrowserRouter(
        createRoutesFromElements(
            <Route element={<RoutedApp />}>
                <Route path="/" element={<ReportBrowser />} />
            </Route>
        ), { basename: `/ett_audit` }
    ), []);

    return (
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    );
}


function RoutedApp() {
    return (
        <ErrorBoundary>
            <StateContextProvider>
                <EffectsContextProvider>
                    <AccessibleUnitsGuard>
                        <ExternalFilterParams>
                            <DataFromApi>
                                <Outlet />
                            </DataFromApi>
                        </ExternalFilterParams>
                    </AccessibleUnitsGuard>
                </EffectsContextProvider>
            </StateContextProvider>
        </ErrorBoundary>
    );
}
