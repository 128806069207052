import * as React from "react";
import { EmployeeDay } from "../state/state";

type GridEttTimesProps = {
    employeeDay: EmployeeDay;
}

export default function GridEttTimes(props: GridEttTimesProps) {
    const { employeeDay } = props;
    return (
        <div className="EttAuditGrid__EmployeeDay__Col EttAuditGrid__EmployeeDay__EttTimes">
            {employeeDay.ettTimes.map(ettTime => (
                <div
                    className={`EttAuditGrid__EmployeeDay__EttTimes__EttTime ${
                        ettTime.mismatched ? "EttAuditGrid__EmployeeDay__EttTimes__EttTime--mismatched" : ""
                    }`}
                    key={`${ettTime.startTime} - ${ettTime.endTime}`}
                >
                    {ettTime.startTime} - {ettTime.endTime}
                    {" "}
                    <div
                        className={`EttAuditGrid__EmployeeDay__EttTimes__EttTime__BreakTime ${ettTime.breakMismatched ? "EttAuditGrid__EmployeeDay__EttTimes__EttTime__BreakTime--mismatched" : ""}`}>
                        ({ettTime.breakTime})
                    </div>
                </div>
            ))}
        </div>
    );
}
