import { useReportState } from "./StateContextProvider";
import * as React from "react";
import { LoadingSpinner } from "../../lib/empty-state/Loading";
import { useEffect } from "react";
import { AccessibleUnitRef } from "./state/state";
import { useReportEffects } from "./EffectsContextProvider";

type AccessibleUnitsGuardProps = {
    children: React.ReactNode;
}
export default function AccessibleUnitsGuard(props: AccessibleUnitsGuardProps) {
    const { children } = props;

    const { accessibleUnitRefs, loadAccessibleUnitsError, loadingAccessibleUnits } = useReportState();
    const {
        startLoadingAccessibleUnits,
        succeedLoadingAccessibleUnits,
        failLoadingAccessibleUnits
    } = useReportEffects();

    const unitRefsCacheKey = accessibleUnitRefs?.map(ref => ref.id).join(",");
    useEffect(() => {
        if (!accessibleUnitRefs && !loadingAccessibleUnits) {
            startLoadingAccessibleUnits();
            loadAccessibleUnits()
                .then(succeedLoadingAccessibleUnits)
                .catch(failLoadingAccessibleUnits);
        }
    }, [unitRefsCacheKey, loadingAccessibleUnits]);

    if (loadAccessibleUnitsError) {
        return <div>Error</div>;
    }

    if (loadingAccessibleUnits) {
        return <LoadingSpinner />;
    }

    if (!accessibleUnitRefs?.length) {
        return <div>Keine Filialen gefunden.</div>;
    }

    return <>{children}</>;
}

export async function loadAccessibleUnits(): Promise<AccessibleUnitRef[]> {
    const url = new URL(`/units.json`, window.location.origin);

    const headers = {
        "Accept": "application/json"
    };

    const response = await fetch(url, { headers });

    if (!response.ok) {
        throw new Error("Failed to load accessible units");
    }

    const json = await response.json();

    return json.map((unit: { id: string, name: string }) => ({
        id: unit.id,
        name: unit.name
    }));
}

