import * as React from "react";
import { HTMLAttributes } from "react";
import { joinClasses } from "../dom-utils/class-names";

export type IconType =
    | (
    | "select"
    | "edit"
    | "goto"
    | "selected"
    | "warning-circle"
    | "previous"
    | "forward"
    | "back"
    | "next"
    | "cancel"
    | "save"
    | "link"
    | "export-excel"
    | "workplace"
    | "different-cost-unit"
    | "delete"
    | "employee-note"
    | "deployment"
    | "absence"
    | "new"
    | "new-alt"
    | "apply"
    | "expand"
    | "collapse"
    | "approved"
    | "unapproved"
    | "absence-certification-full"
    | "absence-certification-partial"
    | "absence-certification-none"
    | "close"
    | "context-actions"
    | "done"
    | "all-done"
    | "follow-link"
    | "error"
    | "file-upload"
    | "file"
    | "shortcuts"
    | "send-mail"
    | "call-phone"
    | "call-mobile"
    | "personal-data"
    | "gender-male"
    | "gender-female"
    | "gender-other"
    | "contextual-info"
    | "build-document"
    | "documents"
    | "success"
    | "export"
    | "auth-code"
    | "log"
    | "app"
    | "ett-report"
    | "logout"
    | "time-frame"
    | "time-frame-separator"
    | "work-hub-device"
    | "unit"
    | "department"
    | "undo"
    )
    | string;

type ElementProps = HTMLAttributes<HTMLSpanElement> & {
    type: IconType;
};

export default function Icon({ type, ...elementProps }: ElementProps): JSX.Element {
    let iconClass: string;

    switch (type) {
        case "select":
            iconClass = "bi-chevron-expand";
            break;
        case "edit":
            iconClass = "bi-pencil";
            break;
        case "goto":
            iconClass = "bi-box-arrow-right";
            break;
        case "selected":
            iconClass = "bi-check";
            break;
        case "warning-circle":
            iconClass = "bi-exclamation-circle";
            break;
        case "next":
            iconClass = "bi-arrow-right";
            break;
        case "previous":
            iconClass = "bi-arrow-left";
            break;
        case "forward":
            iconClass = "bi-chevron-right";
            break;
        case "back":
            iconClass = "bi-chevron-left";
            break;
        case "cancel":
            iconClass = "bi-x-lg";
            break;
        case "save":
            iconClass = "bi-save";
            break;
        case "export-excel":
            iconClass = "bi-file-earmark-spreadsheet";
            break;
        case "workplace":
            iconClass = "bi-building";
            break;
        case "different-cost-unit":
            iconClass = "bi-piggy-bank-fill";
            break;
        case "delete":
            iconClass = "bi-trash";
            break;
        case "employee-note":
            iconClass = "bi-card-text";
            break;
        case "deployment":
            iconClass = "bi-person-fill-up";
            break;
        case "absence":
            iconClass = "bi-person-fill-slash";
            break;
        case "new":
            iconClass = "bi-plus-square";
            break;
        case "new-alt":
            iconClass = "bi-plus";
            break;
        case "apply":
            iconClass = "bi-check-lg";
            break;
        case "expand":
            iconClass = "bi-arrows-expand";
            break;
        case "collapse":
            iconClass = "bi-arrows-collapse";
            break;
        case "approved":
            iconClass = "bi-check-circle";
            break;
        case "unapproved":
            iconClass = "bi-slash-circle";
            break;
        case "absence-certification-full":
            iconClass = "bi-check-circle-fill";
            break;
        case "absence-certification-partial":
            // As requested by Julian, both stati should look the same to keep it simple.
            iconClass = "bi-check-circle-fill";
            break;
        case "absence-certification-none":
            iconClass = "bi-circle";
            break;
        case "absence-approved":
            iconClass = "bi-check-circle-fill";
            break;
        case "absence-not-approved":
            iconClass = "bi-circle";
            break;
        case "close":
            iconClass = "bi-x";
            break;
        case "context-actions":
            iconClass = "bi-three-dots-vertical";
            break;
        case "all-done":
            iconClass = "bi-check-all";
            break;
        case "done":
            iconClass = "bi-check";
            break;
        case "follow-link":
            iconClass = "bi-chevron-right";
            break;
        case "error":
            iconClass = "bi-exclamation-circle";
            break;
        case "file-upload":
            iconClass = "bi-cloud-upload";
            break;
        case "file":
            iconClass = "bi-file-earmark-binary";
            break;
        case "shortcuts":
            iconClass = "bi-lightning-charge-fill";
            break;
        case "send-mail":
            iconClass = "bi-envelope";
            break;
        case "call-phone":
            iconClass = "bi-telephone";
            break;
        case "call-mobile":
            iconClass = "bi-phone";
            break;
        case "personal-data":
            iconClass = "bi-person-lines-fill";
            break;
        case "gender-male":
            iconClass = "bi-gender-male";
            break;
        case "gender-female":
            iconClass = "bi-gender-female";
            break;
        case "gender-other":
            iconClass = "bi-gender-ambiguous";
            break;
        case "contextual-info":
            iconClass = "bi-question";
            break;
        case "build-document":
            iconClass = "bi-stars";
            break;
        case "documents":
            iconClass = "bi-files";
            break;
        case "success":
            iconClass = "bi-check";
            break;
        case "export":
            iconClass = "bi-upload";
            break;
        case "auth-code":
            iconClass = "bi-person-badge";
            break;
        case "log":
            iconClass = "bi-list-columns-reverse";
            break;
        case "app":
            iconClass = "bi-app-indicator";
            break;
        case "ett-report":
            iconClass = "bi-bar-chart-steps";
            break;
        case "logout":
            iconClass = "bi-power";
            break;
        case "time-frame":
            iconClass = "bi-clock";
            break;
        case "time-frame-separator":
            iconClass = "bi-arrow-right";
            break;
        case "work-hub-device":
            iconClass = "bi-tablet";
            break;
        case "unit":
            iconClass = "bi-geo";
            break;
        case "department":
            iconClass = "bi-border-inner";
            break;
        case "undo":
            iconClass = "bi-arrow-counterclockwise";
            break;
        default:
            if (type.trim().length > 0) {
                iconClass = `bi-${type}`;
            } else {
                iconClass = "bi-patch-question";
            }
            break;
    }

    elementProps.className = joinClasses("bi", iconClass, elementProps.className);

    return <span {...elementProps} />;
}
