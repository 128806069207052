import * as React from "react";
import { PersonalSchedule } from "../../../state/types/personal-schedule";
import Figure from "../../../shared/Figure";

type WeeklyBalancesProps = {
    personalSchedule: PersonalSchedule
}

export default function WeeklyBalances(props: WeeklyBalancesProps) {
    const { personalSchedule } = props;

    return (
        <div className="WorkHub__PersonalSchedule__WeeklyBalances">
            <Figure
                title="Ist-Stunden"
                subtitle="Aktuelle Woche"
                value={`${personalSchedule.balances.humanTimeSheetsFinalCreditedWorkDuration} h`}
            />
            <Figure
                title="Soll"
                value={`${personalSchedule.balances.humanTimeSheetsTargetDuration} h`}
            />
        </div>
    );
}
