import * as React from "react";
import { Day } from "../state/state";
import { DateTime } from "luxon";
import GridEmployeeDay from "./GridEmployeeDay";
import GridDayHeader from "./GridDayHeader";

type DayProps = {
    day: Day;
    onApprove: (date: DateTime, employeeId: string) => void;
    onUnapprove: (date: DateTime, employeeId: string) => void;
}

export default function GridDay(props: DayProps) {
    const { day, onApprove, onUnapprove } = props;
    return (
        <div className="EttAuditGrid__Day" key={day.date.toString()}>
            <GridDayHeader date={day.date} />
            {day.employeeDays.map(employeeDay => (
                <GridEmployeeDay
                    key={employeeDay.employeeRef.id}
                    employeeDay={employeeDay}
                    onApprove={() => onApprove(day.date, employeeDay.employeeRef.id)}
                    onUnapprove={() => onUnapprove(day.date, employeeDay.employeeRef.id)}
                />
            ))}
        </div>
    );
}
