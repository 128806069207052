import { DateTime } from "luxon";

export type State = {
    accessibleUnitRefs: AccessibleUnitRef[] | null,
    loadingAccessibleUnits: boolean,
    loadAccessibleUnitsError: string | null,

    filterParamsRestored: boolean;

    firstYear: number,
    unitIds: string[],
    year: number,
    week: number,
    thresholds: ThresholdsValue,
    showApproved: boolean,
    showMatching: boolean,
    loading: boolean,
    loadingError: string | null,
    days: Day[],
    filteredDays: Day[],
}

export type Day = {
    date: DateTime,
    employeeDays: EmployeeDay[],
}

export type EmployeeDay = {
    employeeRef: {
        id: string,
        name: string,
    },
    approved: boolean,
    approvedBy: string,
    approvedOn: string,
    ettTimes: EttTime[],
    deployedTimes: DeployedTime[],
}

type EttTime = {
    startTime: string,
    endTime: string
    breakTime: string,
    mismatched: boolean,
    breakMismatched: boolean,
}

type DeployedTime = {
    startTime: string,
    endTime: string,
    breakTime: string,
    mismatched: boolean,
    breakMismatched: boolean,
    deploymentRef: {
        id: string,
        url: string,
    },
}

export type AccessibleUnitRef = {
    id: string;
    name: string;
}

export type ThresholdsValue = {
    lowerStart: number;
    upperStart: number;
    lowerEnd: number;
    upperEnd: number;
    lowerBreak: number;
    upperBreak: number;
}

export function createInitialState(): State {
    return {
        accessibleUnitRefs: null,
        loadingAccessibleUnits: false,
        loadAccessibleUnitsError: null,

        filterParamsRestored: false,

        firstYear: 2019,
        unitIds: [],
        year: DateTime.local().weekYear,
        week: DateTime.local().weekNumber,
        thresholds: {
            lowerStart: 15,
            upperStart: 1,
            lowerEnd: 1,
            upperEnd: 15,
            lowerBreak: 15,
            upperBreak: 1,
        },
        showApproved: false,
        showMatching: false,
        loading: false,
        loadingError: null,
        days: [],
        filteredDays: []
    };
}
