import { getStorageKey } from "../../lib/storage/storage";

export function getFilterFromStorage(filterName: string) {
    const storageKey = getFilterStorageKey(filterName);
    const storedValue = localStorage.getItem(storageKey);
    return storedValue ? JSON.parse(storedValue) : null;
}

export function setFilterToStorage(filterName: string, value: string) {
    const storageKey = getFilterStorageKey(filterName);
    localStorage.setItem(storageKey, JSON.stringify(value));
}

function getFilterStorageKey(filterName: string) {
    return getStorageKey("ett-audit", "filter", filterName);
}
