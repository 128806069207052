import * as React from "react";
import { EmployeeDay } from "../state/state";

type GridEmployeeProps = {
    employeeRef: EmployeeDay["employeeRef"];
}

export default function GridEmployee(props: GridEmployeeProps) {
    const { employeeRef } = props;
    return (
        <div className="EttAuditGrid__EmployeeDay__Col EttAuditGrid__EmployeeDay__Employee">
            <a
                href={`/employee_manager/${employeeRef.id}`}
                className="EttAuditGrid__EmployeeDay__Col EttAuditGrid__EmployeeDay__Employee__Name"
                target="_blank"
                rel="noreferrer"
            >
                {employeeRef.name}
            </a>
        </div>
    );
}
