import * as React from "react";
import { useFilterParams } from "./useFilterParams";
import ComboBox from "../../../lib/form/select/ComboBox";
import { useNavigateReport } from "./useNavigateReport";
import { DUnit } from "../types";
import ExportButton from "./ExportButton";
import YearNavBar from "../../../lib/form/year-selector/YearNavBar";
import Chrome from "../../../lib/chrome/Chrome";

type FilterBarProps = {
    units: DUnit[];
};

export default function FilterBar(props: FilterBarProps) {
    const { units } = props;

    const { unitIds, year } = useFilterParams();

    const navigateReport = useNavigateReport();

    const unitOptions = {
        Alle: units.reduce((memo, unit) => {
            memo.push({ label: unit.name, value: unit.id });
            return memo;
        }, []),
    };

    return (
        <Chrome.DetailPage.ActionBar split>
            <div className="d-flex gap-2">
                <div style={{ flex: "0 0 500px" }}>
                    <ComboBox
                        allLabel="Alle Filialen"
                        multiselect={true}
                        value={unitIds}
                        groupedOptions={unitOptions}
                        onChange={(newUnitIds) => navigateReport({ unitIds: newUnitIds as string[] })}
                    />
                </div>
                <div>
                    {year && (
                        <YearNavBar
                            aria-label="Zeitraumsauswahl"
                            value={year}
                            onChange={(year) => navigateReport({ year: year })}
                        />
                    )}
                </div>
            </div>
            <div>
                <ExportButton />
            </div>
        </Chrome.DetailPage.ActionBar>
    );
}
