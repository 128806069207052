import * as React from "react";
import { EmployeeDay } from "../state/state";
import Icon from "../../../lib/icon/Icon";

type GridDeployedTimesProps = {
    deployedTimes: EmployeeDay["deployedTimes"];
}

export default function GridDeployedTimes(props: GridDeployedTimesProps) {
    const { deployedTimes } = props;
    return (
        <div className="EttAuditGrid__EmployeeDay__Col EttAuditGrid__EmployeeDay__DeployedTimes">
            {deployedTimes.map(deployedTime => (
                <div
                    className={`EttAuditGrid__EmployeeDay__DeployedTimes__DeployedTime ${
                        deployedTime.mismatched ? "EttAuditGrid__EmployeeDay__DeployedTimes__DeployedTime--mismatched" : ""
                    }`}
                    key={`${deployedTime.startTime} - ${deployedTime.endTime}`}
                >
                    <div
                        className="EttAuditGrid__EmployeeDay__Col EttAuditGrid__EmployeeDay__DeployedTimes__DeployedTime__Time">
                        {deployedTime.startTime} - {deployedTime.endTime}
                        {" "}
                        <div
                            className={`EttAuditGrid__EmployeeDay__DeployedTimes__DeployedTime__Time__BreakTime ${deployedTime.breakMismatched ? "EttAuditGrid__EmployeeDay__DeployedTimes__DeployedTime__Time__BreakTime--mismatched" : ""}`}>
                            ({deployedTime.breakTime})
                        </div>
                    </div>
                    <div
                        className="EttAuditGrid__EmployeeDay__Col EttAuditGrid__EmployeeDay__DeployedTimes__DeployedTime__Actions">
                        <a
                            href={deployedTime.deploymentRef.url}
                            target="_blank" rel="noreferrer"
                        >
                            <Icon type="follow-link" />
                        </a>
                    </div>
                </div>
            ))}
        </div>
    );
}
