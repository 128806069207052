import * as React from "react";
import { useReportState } from "./StateContextProvider";
import { Loading } from "./report-grid/Loading";
import { Empty } from "./report-grid/Empty";
import { Header } from "./Header";
import ReportGrid from "./report-grid/ReportGrid";

export function ReportBrowser() {
    const { filteredDays } = useReportState();

    const { loading, loadingError } = useReportState();
    const showEmpty = !loading && !loadingError && Object.keys(filteredDays).length === 0;
    const showGrid = !loading && !loadingError && Object.keys(filteredDays).length > 0;

    return (
        <div className="EttAudit">
            <Header />
            <div className="EttAudit__Content">
                {loading && <Loading />}
                {loadingError && <div className="EttAudit__LoadingError">{loadingError}</div>}
                {showEmpty && <Empty />}
                {showGrid && <ReportGrid />}
            </div>
        </div>
    );
}
