import * as React from "react";
import { DateTime } from "luxon";
import { formatDate } from "../../../lib/datetime/date-format";

type DayDateProps = {
    date: DateTime;
}

export default function GridDayHeader(props: DayDateProps) {
    const { date } = props;
    return (
        <div className="EttAuditGrid__Day__Header">
            <div className="EttAuditGrid__Day__Header__Date">
                {formatDate(date)}
            </div>
            <div className="EttAuditGrid__Day__Header__ColCaption">
                Stempelzeit
            </div>
            <div className="EttAuditGrid__Day__Header__ColCaption">
                Geplanter Einsatz
            </div>
            <div>

            </div>
        </div>
    );
}
