import * as React from "react";
import { useMemo } from "react";
import { joinClasses } from "../../dom-utils/class-names";
import { DateTime } from "luxon";
import Icon from "../../icon/Icon";

interface WeekSelectProps {
    value: number;
    year?: number;
    onChange: (year: number) => void;
}

export default function WeekSelect(props: WeekSelectProps) {
    const { value: selectedWeek, onChange } = props;

    const year = props.year || DateTime.local().weekYear;
    const firstWeek = 1;
    const lastWeek = useMemo(() => DateTime.local(year).weeksInWeekYear, [year]);

    const weeksRange = useMemo(() => {
        const weeks = [];
        for (let i = firstWeek; i <= lastWeek; i++) {
            weeks.push(i);
        }
        return weeks;
    }, [firstWeek, lastWeek]);

    const prevWeek = selectedWeek === firstWeek ? lastWeek : selectedWeek - 1;
    const nextWeek = selectedWeek === lastWeek ? firstWeek : selectedWeek + 1;

    const weekOptions = useMemo(() => {
        return weeksRange.map((week) => {
            const startDt = DateTime.fromObject({
                weekYear: year,
                weekNumber: Number(week)
            }).toISODate();

            const endDt = DateTime.fromObject({
                weekYear: year,
                weekNumber: Number(week)
            }).endOf("week").toISODate();

            return {
                label: `KW ${week} (${startDt} - ${endDt})`,
                value: week
            };
        });
    }, [year, weeksRange]);

    return (
        <div className="d-flex justify-content-center gap-1 w-100" style={{ minWidth: "200px" }}>
            <select
                className="form-select"
                style={{ flex: "1 1 100%", minWidth: "150px" }}
                aria-label="Kalenderwoche"
                value={selectedWeek}
                onChange={(e) => onChange(Number(e.target.value))}
            >
                {weekOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <a
                href="#"
                className={joinClasses("btn btn-sm", "active btn-outline-primary")}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onChange(prevWeek);
                }}
            >
                <Icon type="back" />
            </a>
            <a
                href="#"
                className={joinClasses("btn btn-sm", "active btn-outline-primary")}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onChange(nextWeek);
                }}
            >
                <Icon type="forward" />
            </a>
        </div>
    );
}
