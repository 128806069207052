import { useEffect } from "react";
import { useReportState } from "./StateContextProvider";
import { useReportEffects } from "./EffectsContextProvider";
import { convertKeysToCamelCase } from "../../lib/object/objects";
import { Day, ThresholdsValue } from "./state/state";

export function useReportFromApi() {
    const { startLoading, succeedLoading, failLoading } = useReportEffects();

    const { thresholds, week, year, unitIds, showMatching } = useReportState();

    const filterParams: FilterParams = {
        thresholds,
        week,
        year,
        unitIds,
        showMatching
    };

    useEffect(() => {
            if (unitIds.length !== 0) {
                startLoading();
                loadReport(filterParams)
                    .then(report => {
                        succeedLoading(report.days);
                        return report;
                    }).catch(error => {
                        failLoading(error.message);
                    }
                );
            }
        }, [
            JSON.stringify(thresholds),
            week,
            year,
            JSON.stringify(unitIds),
            showMatching
        ]
    );
}

type FilterParams = {
    thresholds: ThresholdsValue,
    week: number,
    year: number,
    unitIds: string[],
    showMatching: boolean
}

type ApiReport = {
    days: Day[]
}

export async function loadReport(filterParams: FilterParams): Promise<ApiReport> {
    const url = new URL(`/api/ett_audit/report`, window.location.origin);
    url.searchParams.append("unit_ids", filterParams.unitIds.join(","));
    url.searchParams.append("week", filterParams.week.toString());
    url.searchParams.append("year", filterParams.year.toString());
    url.searchParams.append("lower_start", filterParams.thresholds.lowerStart.toString());
    url.searchParams.append("upper_start", filterParams.thresholds.upperStart.toString());
    url.searchParams.append("lower_end", filterParams.thresholds.lowerEnd.toString());
    url.searchParams.append("upper_end", filterParams.thresholds.upperEnd.toString());
    url.searchParams.append("lower_break", filterParams.thresholds.lowerBreak.toString());
    url.searchParams.append("upper_break", filterParams.thresholds.upperBreak.toString());
    url.searchParams.append("show_matching", filterParams.showMatching.toString());

    const headers = {
        "Accept": "application/json"
    };

    const response = await fetch(url, { headers });

    if (!response.ok) {
        throw new Error("Failed to load report");
    }

    const json = await response.json();

    if (!json.data) {
        throw new Error("Invalid response");
    }

    return convertKeysToCamelCase(json.data) as ApiReport;
}

export async function postEmployeeDayApproval(date, employeeId) {
    return employeeDayApprovalRequest(date, employeeId, "POST");
}

export async function deleteEmployeeDayApproval(date, employeeId) {
    return employeeDayApprovalRequest(date, employeeId, "DELETE");
}

export async function employeeDayApprovalRequest(date, employeeId, method: "POST" | "DELETE") {
    const url = new URL(`/api/ett_audit/employee_days/${employeeId}@${date}/approval`, window.location.origin);

    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
    };

    const response = await fetch(url, { method, headers });

    if (!response.ok) {
        throw new Error("Failed to approve/unapprove employee day");
    }

    return true;
}
