import * as React from "react";
import { ThresholdsValue } from "./state/state";

type ThresholdsControlProps = {
    thresholds: ThresholdsValue;
    onChange: (thresholds: ThresholdsValue) => void;
}
export default function ThresholdsControl(props: ThresholdsControlProps) {
    const { thresholds: { lowerStart, upperStart, lowerEnd, upperEnd, lowerBreak, upperBreak }, onChange } = props;

    const handleThresholdValueChanged = (key: keyof ThresholdsValue) => (value: number) => {
        onChange({
            ...props.thresholds,
            [key]: value
        });
    };

    return (
        <div className="EttAudit__ThresholdsControl d-flex gap-1">
            <div className="d-flex flex-column gap-1 border p-1 rounded align-items-center">
                <div className="text-center" style={{ width: "144px" }}>
                    Warnschwelle Beginn
                </div>
                <div className="d-flex gap-1">
                    <div className="text-center" style={{ width: "80px" }}>
                        Früher (min)
                    </div>
                    <div className="text-center" style={{ width: "80px" }}>
                        Später (min)
                    </div>
                </div>
                <div className="d-flex gap-1">
                    <input
                        type="number"
                        className="form-control"
                        aria-label="Schwellenwert Arbeitsbeginn früher"
                        value={lowerStart}
                        style={{ width: "80px" }}
                        onChange={e => handleThresholdValueChanged("lowerStart")(parseInt(e.target.value))}
                    />
                    <input
                        type="number"
                        className="form-control"
                        aria-label="Schwellenwert Arbeitsbeginn später"
                        value={upperStart}
                        style={{ width: "80px" }}
                        onChange={e => handleThresholdValueChanged("upperStart")(parseInt(e.target.value))}
                    />
                </div>
            </div>

            <div className="d-flex flex-column gap-1 border p-1 rounded align-items-stretch">
                <div className="text-center" style={{ width: "144px" }}>
                    Warnschwelle Ende
                </div>
                <div className="d-flex gap-1">
                    <div className="text-center" style={{ width: "80px" }}>
                        Früher (min)
                    </div>
                    <div className="text-center" style={{ width: "80px" }}>
                        Später (min)
                    </div>
                </div>
                <div className="d-flex gap-1">
                    <input
                        type="number"
                        className="form-control"
                        aria-label="Schwellenwert Arbeitsende früher"
                        value={lowerEnd}
                        style={{ width: "80px" }}
                        onChange={e => handleThresholdValueChanged("lowerEnd")(parseInt(e.target.value))}
                    />
                    <input
                        type="number"
                        className="form-control"
                        aria-label="Schwellenwert Arbeitsende später"
                        value={upperEnd}
                        style={{ width: "80px" }}
                        onChange={e => handleThresholdValueChanged("upperEnd")(parseInt(e.target.value))}
                    />
                </div>
            </div>

            <div className="d-flex flex-column gap-1 border p-1 rounded align-items-stretch">
                <div className="text-center" style={{ width: "144px" }}>
                    Warnschwelle Pause
                </div>
                <div className="d-flex gap-1">
                    <div className="text-center" style={{ width: "80px" }}>
                        Kürzer (min)
                    </div>
                    <div className="text-center" style={{ width: "80px" }}>
                        Länger (min)
                    </div>
                </div>
                <div className="d-flex gap-1">
                    <input
                        type="number"
                        className="form-control"
                        aria-label="Schwellenwert Pause früher"
                        value={lowerBreak}
                        style={{ width: "80px" }}
                        onChange={e => handleThresholdValueChanged("lowerBreak")(parseInt(e.target.value))}
                    />
                    <input
                        type="number"
                        className="form-control"
                        aria-label="Schwellenwert Pause später"
                        value={upperBreak}
                        style={{ width: "80px" }}
                        onChange={e => handleThresholdValueChanged("upperBreak")(parseInt(e.target.value))}
                    />
                </div>
            </div>
        </div>
    );
}
